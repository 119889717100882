import React from 'react';
import PropTypes from 'prop-types';
import * as ContainerContext from '@strava/container-context';
import { useBranchIO } from '@strava/react-hooks';
import { createRoot } from 'react-dom/client';
import StravaPackageContextProviders from '../apps/StravaPackageContextProviders';
import { logError, logMessage } from './sentry';

const { BranchContext } = ContainerContext;

const { Provider: BranchContextProvider } = BranchContext;

const BranchContextProxy = ({
  dataSharingOptedOut = false,
  denyNonessentialCookies = true,
  children
}) => {
  const branch = useBranchIO({
    dataSharingOptedOut,
    cookiesAccepted: !denyNonessentialCookies,
    logError
  });

  return (
    <BranchContextProvider value={branch}>{children}</BranchContextProvider>
  );
};

BranchContextProxy.propTypes = {
  dataSharingOptedOut: PropTypes.bool.isRequired,
  denyNonessentialCookies: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

// Object that stores references to all the registered components
let registeredComponents = {};
// Reference to all React root instances
const rootMap = new WeakMap();

// Track a single component that should be mounted after the DOM content has loaded
function register(components) {
  // Registered components are passed in as Hash keys (as opposed to individual arguments)
  // in order to preserve their names during JavaScript minification.
  //
  // For example:
  //    ReactComponentMounter.register({ Hello });

  registeredComponents = { ...registeredComponents, ...components };
}

function mountComponent({ node, component, props }) {
  const className = component || node.getAttribute('data-react-class');
  const reactComponent = registeredComponents[className];

  if (!reactComponent) {
    logMessage(`Cannot find a component registered with the name ${className}`);
    return;
  }

  const propsJson = node.getAttribute('data-react-props');
  try {
    const reactProps = {
      ...(propsJson && JSON.parse(propsJson)),
      ...props
    };

    const { dataSharingOptedOut, denyNonessentialCookies } = reactProps || {};

    let reactElement = React.createElement(reactComponent, reactProps);

    // react-components imported from published `web` packages
    // these packages are developed locally using MFEs so they need
    // providers from @strava/container-context
    const isPublished = node.getAttribute('data-is-published') === 'true';
    if (isPublished) {
      reactElement = (
        <StravaPackageContextProviders>
          <BranchContextProxy
            dataSharingOptedOut={dataSharingOptedOut}
            denyNonessentialCookies={denyNonessentialCookies}
          >
            {reactElement}
          </BranchContextProxy>
        </StravaPackageContextProviders>
      );
    }

    let root = rootMap.get(node);

    if (!root) {
      root = createRoot(node);
      rootMap.set(node, root);
    }

    root.render(reactElement);
  } catch (e) {
    logError(e);
  }
}

// Allows a page to mount a component right away rather than
// wait for the DOMContentLoaded event
function inlineMount(node, component) {
  mountComponent({ node, component });
}

// Search the DOM for all react components to be mounted,
// and mount them into the DOM
function mountComponents() {
  const nodesToMount = document.querySelectorAll('[data-react-class]');
  // IE does not support NodeList.forEach, so we need to do it the old fashion way
  /* eslint-disable-next-line  no-plusplus */
  for (let i = 0; i < nodesToMount.length; ++i) {
    mountComponent({ node: nodesToMount[i] });
  }
}

// Ensure pages can mount components more specifically
if (window.stravaInlineMount === undefined) {
  window.stravaInlineMount = inlineMount;
}

// Add this listener only once, when the module is first imported
document.addEventListener('DOMContentLoaded', mountComponents);

/**
 * Also added once, this listener can be used to render react components
 * that were created after the DOM was loaded via the ruby `react_component` helper
 *
 * example usage:
 *
 * document.dispatchEvent(new CustomEvent('JSCreatedReactNode', {detail: {node: $('#my-react-component')}}))
 * document.dispatchEvent(new CustomEvent('JSCreatedReactNode', {detail: {node: $('#my-react-component')[index]}}))
 */
function mountJSCreatedComponent(e) {
  const { node, component, props } = e.detail;
  if (!node) {
    return;
  }

  const unwrappedNode = node.length ? node[0] : node;
  if (!unwrappedNode) {
    return;
  }

  mountComponent({ node: unwrappedNode, component, props });
}

document.addEventListener('JSCreatedReactNode', mountJSCreatedComponent);

/**
 * Components mounted using certain erb - jquery setup do not go through their whole
 * lifecyle so they are left unmounted as the athlete interacts on the page.
 *
 * For examples, see interval.js.rb, graph_data_range.js.rb and interval_type.js.rb
 */
document.addEventListener('JSUnmountReactNode', (e) => {
  const { node } = e.detail;
  const root = rootMap.get(node);

  if (root) {
    root.unmount();
    rootMap.delete(node);
  }
});

export default { register, mountComponent };
